@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;1,400;2,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import "react-datepicker/dist/react-datepicker.css";
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

/*Login Css*/
.budget_table .field_section{
  margin-bottom: 0px;
}
.field_section{
  margin-bottom: 20px;
}
.field_section .chakra-form__label{
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
   font-family: 'Open Sans', sans-serif;

}
.field_section label{
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
  display: block;
}
.field_section .chakra-input{
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.field_section .chakra-select{
  border: 1px solid #ccc;
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.field_section textarea::placeholder{
  font-size: 12px; 
}

/*Login Css*/

/*Header Css*/

.nav_shadow{
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.header_link{
  color: #000 !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

.header_link.active{
  color: #000 !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

/*Header Css*/

/*Setting Css*/

.setting_page{
  border-top: 1px solid #ccc;
}
.setting_nav_link{
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  font-family: 'Open Sans', sans-serif;
  display: block;
  background-color: #fff;
  padding: 12px 50px;
  width: 96%;
  border-radius: 0px 25px 25px 0px;
}
.setting_nav_link:hover, .setting_nav_link.active{
  background-color: rgba(49, 54, 122, 0.2);
  font-weight: 700;
}
.setting_page_section{
  padding: 20px;
}

.page_section{
  padding-left: 40px;
  padding-right: 40px;
}

/*Setting Css*/

.table_section .chakra-table__container{
  width: 100%;
}
.table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 1px solid #ccc;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead{
  background-color: #31367A;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
}
.accordion_section .table_section .chakra-table__container .chakra-table thead{
  background-color: #fff;
  border-spacing: 0px;
}
.accordion_section .table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
  border-color: #ccc;

}
.accordion_section .table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 0px solid #ccc;
  border-top: 1px solid #ccc;
  border-spacing: 0px;
}

.accordion_section .chakra-menu__menu-list{
  width: 200px;
}
.accordion_section .chakra-menu__menu-list button{
  font-size: 12px;
  padding: 8px 15px;
  display: inline-block;
  height: unset;
}
.accordion_section .chakra-menu__menu-list hr{
  margin: 0px;
} 


.table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}


.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list{
  width: 200px;
}

.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list button{
  font-size: 12px;
  padding: 8px 15px;
  display: inline-block;
  height: unset;
}
.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list hr{
  margin: 0px;
} 

.table_section .chakra-table__container .chakra-table tfoot tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color:#000000;
  padding: 12px 15px;
  text-align: left;
  text-transform: capitalize;
  

}
.table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}
.inner_table_section .chakra-table__container{
  width: 100%;
}
.inner_table_section .chakra-table__container .chakra-table{
  width: 100%;
  border-top: 1px solid #ccc;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead{
  background-color: transparent;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #585858CC;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}





/*Project Edit Page*/

.Project_Edit_page_Section{
    padding: 20px 50px;
}

.Project_Edit_page_Section .Backed{
  font-weight: 400;
  font-size: 14px;
  color: #3B86FF;
}
.Project_Edit_page_Section .Backedicon{
  color: #3B86FF;
  font-size: 24px;
}

.TabsList button{
  font-size: 14px;
  font-weight: 400;
   font-family: 'Open Sans', sans-serif;

}
.TabsList button[aria-selected="true"]{
  font-weight: 800;
  color: #31367A ;
  font-family: 'Open Sans', sans-serif;
  height: -19px;
  border-bottom: 3px solid #31367A;
}

#button1{
  margin: 0px 5px;
  width: 140px  ;
  height: 42px;
  color: #787878;
  border: 1px solid #CCCCCC;
  background-color: #fff;
  font-weight: 400;
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

#button2{
  margin: 0px 5px;
  width: 170px  ;
  height: 42px;
  color: #FFFFFF;
  border: 1px solid #31367A;
  background-color: #31367A;
  font-weight: 400;
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.custom{
  margin-bottom: 20px;
}
.custom label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  display: block;
}

.custom .label{
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
   font-family: 'Open Sans', sans-serif;

}

.custom input{
  color:black;
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}



.Team_Veiw {
/*  padding: 20px;*/
padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.Team_Veiw .Backed {
  font-weight: 300;
  font-size: 32px;
   font-family: "Montserrat", sans-serif;

}

.Team_Veiw .Backedicon{
  color: #000000;
  font-size: 30px;
}


.Box_Section .Box_Area{
  font-size: 12px;
  border: 1px solid rgba(49, 54, 122, 0.05);
  background-color: rgba(49, 54, 122, 0.05);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Box_Section .Box_Area .head_text{
  font-size: 20px;
  font-weight: 400;
}


.Box_Section .Box_Area .Progress div {
 
  background: linear-gradient(to right, rgba(0, 121, 255, 1) 60%, rgba(248, 109, 109, 1) 20%);

}



/*Calender Css*/

.MonthPicker {
  width: 80%;
  border-radius: 10px;
  border:  1px solid black;
/*  cursor: pointer;*/
}
input {
  padding: 8px;
}



/* new Calender Css */
.MonthPicker {
  border-radius: 10px;
/*  cursor: pointer;*/
}

.Team_Veiw .Box_Section .CustomCalender {
  border: 2px solid #ccc;
  padding: 20px;
}
.Team_Veiw .Box_Section .CustomCalender .rbc-off-range-bg {
  background-color: #fff;
}

.Team_Veiw .Box_Section .CustomCalender .rbc-off-range {
  color: #000;
}

.Team_Veiw .Box_Section .CustomCalender .holiday-off {
  background-color: #e6e6e6;
}
.box-section .onleave {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: rgba(238, 42, 77, 1);
  padding: 2px 8px 2px 4px;
  border: 1px solid rgba(238, 42, 77, 1);
  border-radius: 5px;
  text-transform: uppercase;
}

.box-section .holiday {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: rgba(31, 223, 0, 1);
  padding: 2px 8px 2px 4px;
  border: 1px solid rgba(31, 223, 0, 1);
  border-radius: 5px;
  text-transform: uppercase;
}

.box-section .time {
  font-size: 24px;
  font-weight: 400;
}
.box-section .hours {
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 15px;
}

.custom-date-picker {
  width: 80%;
  border-radius: 5px;
  cursor: pointer;
}
.rbc-calendar .rbc-month-view {
  border: 0px;
  border-bottom: 1px solid #ddd;
}
.rbc-calendar .rbc-month-header .rbc-header + .rbc-header {
  border-left: 0px;
}
.rbc-calendar .rbc-month-row {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  overflow: unset;
  height: 127.5px;
}
.rbc-calendar .rbc-month-row .rbc-button-link {
  float: left;
  padding: 5px;
}
.rbc-calendar .rbc-row-segment .rbc-event {
  background-color: transparent !important;
}
.rbc-calendar .rbc-row-segment .rbc-event .rbc-event-content {
  color: #000;
  text-align: center;
  font-size: 20px;
}

.custom-event strong {
  /* Style for the event title */
  font-weight: bold;
}

.event-description {
  /* Style for the event description */
  margin-top: 5px;
}

.on-leave-description {
  font-size: 12px;
  margin-top: 35px;
  color: #fff;
  background-color: rgba(238, 42, 77, 1);
  padding: 2px 8px 2px 4px; /* Corrected padding syntax */
  border: 1px solid rgba(238, 42, 77, 1);
  border-radius: 5px;
  text-transform: uppercase;
}


/* //model Css */
/*
.chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.8) !important;
  opacity: unset !important;
}
.chakra-modal__content-container {
  width: 791px !important;
  right: 0px !important;
  left: unset !important;
  justify-content: right !important;
}
.chakra-modal__content {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  min-height: 100vh;
  background: #f6f6f6 !important;
}

.chakra-modal__header {
  background: #f0f8ff !important;
  font-size: 19px;
  font-weight: 600;
  padding-right: 30px;
  line-height: 22.67px;
}*/

.chakra-checkbox__label {
  font-size: 19px;
  font-weight: 400;
}

.chakra-checkbox__control {
  height: 24px;
  width: 24px;
  border: 1px solid #cccccc;
}

.chakra-button s{
  background-color: #0080ff;
  color: #ffffff;
  padding: 9px 20px 9px 20px;
}
.chakra-button.Design-Label {
  padding: 10px;
  background-color: #000000;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
/*  width: 59px;*/
  height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
.chakra-button.Reviewed-Label {
  padding: 10px;
  background-color: #2ce576;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  width: 74px;
  height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
.chakra-button.not-Reviewed-Label {
  padding: 10px;
  background-color: #ff4040;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  width: 99px;
  height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}

.chakra-text span {
  color: #0080ff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.box-section.not_click .time {
  font-size: 24px;
  font-weight: 400;
  color: #888888;
}
.box-section.not_click .hours {
  font-size: 10px;
  color: #888888;
  font-weight: 400;
  margin-bottom: 15px;
}
.total-hours-container {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.total-hours-cell {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 10px;
  height: 128.23px;
  width: 119px !important;
  font-size: 16px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.box-section.total-hours-cell .hours {
  color: #888888;
}
.employee_box .text_section h2{
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
}
.employee_box .text_section p{
    font-weight: 400;
    font-size: 12px;
    color: #00000080;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
}




.react-datepicker__input-container .react-datepicker__calendar-icon{
  right: 51px;
}


.css-r6z5ec {
  z-index: 10  !important;
}
